export const defaultPartner = {
    '_id' : '57309fff882ba82a4c2d98ed',
    'name' : 'NUVIAD',
    'apps' : [
      {
        'app' : 'dashboard',
        'domain' : 'my.nuviad.com',
        'logo_white' : '//res.cloudinary.com/nuviad/image/upload/v1473005055/logo_on_black_rgb_600_166_aqfksl.png',
        'logo_dark' : '//res.cloudinary.com/nuviad/image/upload/v1473004711/logo_final_rgb_600_173_psmwjv.png',
        'title' : 'NUVIAD',
      },
      {
        'app' : 'express',
        'domain' : 'express.nuviad.com',
        'logo_white' : '//res.cloudinary.com/nuviad/image/upload/v1473005055/logo_on_black_rgb_600_166_aqfksl.png',
        'logo_dark' : '//res.cloudinary.com/nuviad/image/upload/v1473004711/logo_final_rgb_600_173_psmwjv.png',
        'title' : 'NUVIAD Express',
        'radius_unit' : 'miles',
      },
      {
        'app' : 'preview_tags',
        'domain' : 'tags.nuviad.com',
      },
    ],
    'design' : {
      'logo_white' : '//res.cloudinary.com/nuviad/image/upload/v1462804602/NUVIAD_logo_white_rgb_600_113_1_gmtjig.png',
      'logo_dark' : '//res.cloudinary.com/nuviad/image/upload/v1462804605/NUVIAD_logo_rgb_600_113_1_arspya.png',
      'icon' : [
        {
          'size' : '16x16',
          'url' : '//res.cloudinary.com/nuviad/image/upload/v1473084845/favicon-16x16_iwow7l.png',
        },
        {
          'size' : '32x32',
          'url' : '//res.cloudinary.com/nuviad/image/upload/v1473084845/favicon-32x32_w8e1uc.png',
        },
        {
          'size' : '96x96',
          'url' : '//res.cloudinary.com/nuviad/image/upload/v1473084845/favicon-96x96_ht4vmz.png',
        },
        {
          'size' : '192x192',
          'url' : '//res.cloudinary.com/nuviad/image/upload/v1473084845/android-icon-192x192_rsx3yw.png',
        },
      ],
    },
    'countries' : [
      'ALL',
    ],
  };