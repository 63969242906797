import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { Form, Button, Spinner, Alert } from 'react-bootstrap';

interface FormValues {
  email: string;
  password: string;
}

const validationSchema = yup.object({
  email: yup.string().required(),
  password: yup.string().required(),
});

interface SigninResult {
  success: true;
  user: {
    _id: string;
    first_name: string;
    last_name: string;
    email: string;
    last_login_at: string;
    created_at: string;
    modified_at: string;
  };
  account: {
    id: string;
  };
  token: string;
  //token_expiration: string;
  //renew_token: string;
  //renew_token_expiration: string;
}

interface ErrorResult {
  success: false;
  status_code: number;
  message: any;
  data: any;
}

interface FiledValidationError {
  message: string;
  path: string;
}

const submitHandler = async (
  values: FormValues,
  formikHelpers: FormikHelpers<FormValues>
) => {
  try {
    const trimmedValues = Object.fromEntries(
      Object.entries(values).map(([key, value]) => [key, value.trim()])
    );
    const partner = JSON.parse(
      atob(window.localStorage.getItem('partner') || '') || '""'
    );
    const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        White_label_id: partner?._id,
      },
      body: JSON.stringify(trimmedValues),
    });
    //Auth response ok
    if (response.ok) {
      const signinResult = (await response.json()) as SigninResult;

      // save user and token to local storage
      window.localStorage.setItem(
        'user',
        btoa(JSON.stringify(signinResult.user))
      );

      window.localStorage.setItem('token', btoa(signinResult.token));
      window.localStorage.setItem('accountId', signinResult.account.id);

      // window.localStorage.setItem(
      //   'token_expiration',
      //   signinResult.token_expiration
      // );
      // window.localStorage.setItem(
      //   'renew_token',
      //   btoa(signinResult.renew_token)
      // );
      // window.localStorage.setItem(
      //   'renew_token_expiration',
      //   signinResult.renew_token_expiration
      // );

      // redirect to admin app
      const lastLocation = window.localStorage.getItem('lastLocation');
      if (lastLocation) {
        window.location.replace(
          process.env.REACT_APP_APP_URL!.replace('/home', '') + lastLocation
        );
        window.localStorage.removeItem('lastLocation');
      } else {
        window.location.replace(process.env.REACT_APP_APP_URL!);
      }
    }
    //Auth response not ok
    else {
      try {
        const responseBody = (await response.json()) as ErrorResult;
        formikHelpers.setStatus(responseBody.message);
      } catch (err) {
        formikHelpers.setStatus(response.statusText);
      } finally {
        formikHelpers.setSubmitting(false);
      }
    }
  } catch (err) {
    formikHelpers.setStatus(err.message);
    formikHelpers.setSubmitting(false);
  }
};

export default function SigninForm() {
  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={(values, formikHelpers) => {
        submitHandler(values, formikHelpers);
      }}
      initialValues={{
        email: '',
        password: '',
      }}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        isSubmitting,
        status,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              name="email"
              value={values.email}
              onChange={handleChange}
              placeholder="Enter email"
              isInvalid={touched.email && !!errors.email}
              disabled={isSubmitting}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              value={values.password}
              onChange={handleChange}
              placeholder="Enter password"
              isInvalid={touched.password && !!errors.password}
              disabled={isSubmitting}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
          </Form.Group>
          {status && <Alert variant="danger">{status}</Alert>}
          <Button
            type="submit"
            className="btn-brand-02 btn-block"
            disabled={isSubmitting}
          >
            {!isSubmitting && 'Sign In'}
            {isSubmitting && (
              <Spinner animation="border" variant="light" size="sm" as="span" />
            )}
          </Button>
        </Form>
      )}
    </Formik>
  );
}
