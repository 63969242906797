import React, { useEffect } from 'react';
//import moment from 'moment';

import './assets/css/dashforge.scss';

import SigninImage from './assets/images/signin.png';
import { defaultPartner } from './default-partner-data';
import SigninForm from './SigninForm';
import * as Sentry from '@sentry/react';

// if (window.localStorage.getItem('token')) {
//   if (window.location.hostname !== 'localhost') {
//     // const tokenExpiration = window.localStorage.getItem('token_expiration');
//     // const exTime = moment(tokenExpiration);
//     // const now = moment();
//     // if (now.isBefore(exTime)) {
//     //   window.location.replace(process.env.REACT_APP_APP_URL!);
//     // }
//     window.location.replace(process.env.REACT_APP_APP_URL!);
//   }
// }

async function getPartnerData() {
  //Get partner data
  const host = window.location.hostname;
  //const host = "my.hubzity.com";//For testing hubzity
  //const host = "my.36presents.com";//For testing 36presents
  const parnterResponse = await fetch(
    `${process.env.REACT_APP_API_URL}/partners/${host}?fallback_default=1`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        app_id: 'admin',
      },
    }
  );
  let partner;
  if (parnterResponse.ok) {
    partner = (await parnterResponse.json()) as any;
  } else {
    partner = defaultPartner;
  }

  //Update favicons
  const favicon = document.getElementById('favicon');
  const appleFavicon = document.getElementById('apple-favicon');
  if (partner && partner.design && partner.design.icon) {
    // @ts-ignore
    const icon16x16 = partner.design.icon.find(i => i.size === '16x16');
    if (icon16x16 && icon16x16.url) {
      // @ts-ignore
      favicon.href = icon16x16.url;
    }
    // @ts-ignore
    const icon192x192 = partner.design.icon.find(i => i.size === '192x192');
    if (icon192x192 && icon192x192.url) {
      // @ts-ignore
      appleFavicon.href = icon192x192.url;
    }
  }

  // save partner to local storage
  window.localStorage.setItem('partner', btoa(JSON.stringify(partner)));
}

function App() {
  useEffect(() => {
    const checkToken = async () => {
      const token = window.localStorage.getItem('token');
      const accountId = window.localStorage.getItem('accountId');
      if (token) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/auth/validate_token`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                account_id: accountId,
                token: atob(token),
              }),
            }
          );
          if (response.status !== 401) {
            window.location.replace(process.env.REACT_APP_APP_URL!);
          }
        } catch (err) {}
      }
    };

    checkToken();
  }, []);

  getPartnerData();

  return (
    <div className="content content-fixed content-auth">
      <div className="container">
        <div className="media align-items-stretch justify-content-center ht-100p pos-relative">
          <div className="media-body align-items-center d-none d-lg-flex">
            <div className="mx-wd-600">
              <img src={SigninImage} className="img-fluid" alt="Sign In" />
            </div>
          </div>
          <div className="sign-wrapper mg-lg-l-50 mg-xl-l-60">
            <div className="wd-100p">
              <h3 className="tx-color-01 mg-b-5">Sign In</h3>
              <p className="tx-color-03 tx-16 mg-b-40">
                Welcome back! Please signin to continue.
              </p>

              <SigninForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sentry.withProfiler(App);
